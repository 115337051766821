<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 更多专利 -->
    <PatentNei></PatentNei>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import PatentNei from "./base/PatentNei";
 export default {
  name: "patent",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    ConTab,
    PatentNei,
  },
};
</script>

<style scoped>
</style>
