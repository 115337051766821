<template>
	<div class="need bc">
		<div class="w">
			<!-- 搜索 -->
			<div class="tip_top">
				<div class="inputs_filter">
					<input type="text" placeholder="请输关键字" v-model="search" />
					<div @click="submit()">搜索</div>
				</div>
			</div>
			<!-- 筛选 -->
			<div class="resource-list-search">
				<div class="resource-search-content">
					<div class="select-content">
						<span class="select-field-title">按类型:</span>
						<div class="select-field-list">
							<ul>
								<li v-for="(item, key) in type" :class="key == index2 ? 'cur' : ''" @click="handle2(item, key)" :key="item.id">{{ item.name }}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="checks_wraps">
				<div class="allLists">
					<ul class="policyWraps clear-fix">
						<li class="policyItem" v-for="(item, idx) in policy" :key="idx">
							<!-- v-if="item.ptype"  -->
							<div class="listBox_card_logo" @click="needs(item.id)">
								<span v-if="item.ptype && item.ptype.id">
									<!-- 商标展示 -->
									<img v-if="item.ptype.id === 137" src="../../../assets/img/zhichanyun/fm.jpg" alt="证书" />
									<!-- 商标展示 -->
									<img v-if="item.ptype.id === 139" src="../../../assets/img/zhichanyun/sb.jpg" alt="证书" />
									<!-- 版权展示 -->
									<img v-else-if="item.ptype.id === 138" src="../../../assets/img/zhichanyun/bq.jpg" alt="证书" />
									<!-- 其他展示 -->
									<img v-else-if="item.ptype.id === 140" src="../../../assets/img/zhichanyun/qt.jpg" alt="证书" />
								</span>
								<!-- <span v-else>
									<img v-if="item.ptypes.name === '发明专利'" src="../../../assets/img/zhichanyun/fm.jpg" alt="证书" />
									<img v-else-if="item.ptypes.name === '实用新型'" src="../../../assets/img/zhichanyun/sy.jpg" alt="证书" />
									<img v-else src="../../../assets/img/zhichanyun/wg.jpg" alt="证书" />
								</span> -->
							</div>
							<!-- <div class="listBox_card_logo" v-else  @click="needs(item.id)">
                <img 
                  src="../../../assets/img/zhichanyun/sy.jpg"
                  alt="证书"
                />
              </div> -->
							<p class="listBox_card_p1 oe" @click="needs(item.id)">{{ item.name }}</p>
							<p class="listBox_card_p3">
								<span class="listBox_card_price">
									<label>￥</label>
									{{ item.sale_money }}元
								</span>
								<span class="tag">{{ item.ptype ? item.ptype.name : '' }}</span>
							</p>
							<p class="listBox_card_tag">{{ item.ptypes ? item.ptypes.name : '.' }}</p>
							<div class="kefu" @click="zx()">立即议价</div>
						</li>
					</ul>
					<!-- 分页 -->
					<div class="pagination" style="margin-top: 49px">
						<pagination
							class="pages"
							v-show="total > 0"
							:total="total"
							layout="prev, pager, next"
							:page.sync="listQuery.page"
							:limit.sync="listQuery.limit"
							@pagination="getPatentList"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { PatentList, PType } from '../../../common/js/api';
import Pagination from '@/common/Pagination';
import { formatDate } from '../../../common/js/times'; //时间

export default {
	name: 'NeedNei',
	components: { Pagination },
	filters: {
		formatDate(time) {
			time = time * 1000;
			let date = new Date(time);
			return formatDate(date, 'yyyy-MM-dd');
		}
	},
	data() {
		return {
			policy: [],
			search: '',
			total: 0, //总条目数
			listQuery: {
				limit: 10,
				page: 1
			},
			index2: 0,
			type: [], //类别
			ItemId: ''
		};
	},
	created() {
		this.search = this.$route.query.keyword;
		if (this.$route.query.i == 0) {
			this.ItemId = 137;
			this.index2 = 1;
		} else if (this.$route.query.i == 1) {
			this.ItemId = 138;
			this.index2 = 2;
		} else if (this.$route.query.i == 2) {
			this.ItemId = 139;
			this.index2 = 3;
		} else if (this.$route.query.i == 3) {
			this.ItemId = 140;
			this.index2 = 4;
		}
		this.getPatentList();
		this.getType();
		// console.log(this.ItemId);
	},

	methods: {
		//智产云 更多专利列表
		getPatentList() {
			this.List();
		},
		// 搜索
		submit() {
			this.List();
		},
		handle2: function(item, idx) {
			this.index2 = idx;
			this.ItemId = item.id;
			this.List();
		},
		List() {
			var data = {
				name: this.search,
				type: this.ItemId,
				page: this.listQuery.page,
				limits: this.listQuery.limit
			};

			PatentList(data)
				.then(res => {
					console.log(this.policy);
					this.policy = res.data.data;
					this.total = res.data.total;
				})
				.catch(() => {});
		},

		// 详情
		needs(id) {
			this.$router.push({
				path: '/ZReleaseXQ',
				query: {
					id: id
				}
			});
		},
		// 咨询客服
		zx() {
			this.$router.push({
				path: '/chat'
			});
		},
		// 类型
		getType() {
			PType({})
				.then(res => {
					var b = { name: '全部' };
					res.data.unshift(b);
					this.type = res.data;
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="less" scoped>
.need{
	margin-top: 135px;
}
.tip_top {
	width: 100%;
	//   height: 34px;
	background-color: #3892eb;
	padding: 5px 0;
	color: #fff;
	padding: 9px 9px 10px 17px;
	line-height: 36px;
	.inputs_filter {
		width: 100%;
		height: 34px;
		line-height: 34px;
		border-radius: 4px;
		cursor: pointer;
		input {
			display: inline-block;
			background-color: #fff;
			border-radius: 20px;
			height: 34px;
			width: 1074px;
			padding: 4px 20px;
			line-height: 26px;
			box-sizing: border-box;
		}
		div {
			display: inline-block;
			width: 90px;
			height: 34px;
			text-align: center;
		}
	}
}
.resource-list-search {
	box-sizing: border-box;
	padding: 10px 40px 25px 20px;
	background: #ffffff;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 4px;
	.select-content {
		margin-top: 15px;
		font-size: 14px;
		// height: 28px;
		line-height: 28px;
		.select-field-title {
			width: 100px;
			display: inline-block;
			color: #333;
			text-align: left;
			font-weight: 500;
		}
		.select-field-list {
			width: 1000px;
			display: inline-block;
			li {
				padding: 0 15px;
				display: inline-block;
				color: #999;
				cursor: pointer;
			}
			li.cur {
				background: #2a71d2;
				border-radius: 2px;
				color: #fff;
			}
		}
	}
}
.checks_wraps {
	.policyItem:hover .kefu {
		height: 36px;
		transition: all 0.35s ease;
	}
	.policyItem:nth-child(5n) {
		margin-right: 0;
	}
	.policyItem {
		cursor: pointer;
		margin: 0 12px 12px 0;
		width: 230px;
		height: 320px;
		background: #fff;
		float: left;
		overflow: hidden;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		position: relative;
		.listBox_card_logo {
			width: 210px;
			height: 210px;
			margin: 10px;
			text-align: center;
			position: relative;
			background: #ccc;
			.listul .listBox_card_logo img {
				max-width: 210px;
				max-height: 210px;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				-o-object-fit: contain;
				object-fit: contain;
			}
		}
		.listBox_card_p1 {
			padding: 0px 10px 10px 10px;
			font-size: 16px;
			color: #464545;
			line-height: 30px;
			height: 30px;
			border-bottom: 1px solid #e5ebf1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.listBox_card_p3 {
			line-height: 50px;
			padding: 0 10px;
			span {
				font-size: 14px;
				color: #a5a5a5;
				vertical-align: middle;
			}
			.listBox_card_price {
				color: #fb6514 !important;
				font-size: 14px !important;
				font-weight: bold;
			}
			.tag {
				position: relative;
				float: right;
			}
		}

		.listBox_card_tag {
			position: absolute;
			top: 10px;
			left: 10px;
			width: 87px;
			height: 25px;
			display: inline-block;
			font-size: 12px;
			color: #fff;
			line-height: 25px;
			text-indent: 8px;
			margin-right: 1px;
			background: url(../../../assets/img/zhichanyun/i16.png) no-repeat center;
		}
		.kefu {
			width: 210px;
			height: 0px;
			overflow: hidden;
			display: inline-block;
			background-color: #2b3d63;
			border-radius: 4px;
			color: #fff;
			font-size: 15px;
			line-height: 36px;
			text-align: center;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			position: absolute;
			bottom: 7px;
			left: 10px;
		}
	}
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
		overflow: hidden;
	}
	.need{
		margin-top: 15rem;
	}
	.tip_top .inputs_filter input{
		width: 88%;
	}
	.tip_top .inputs_filter{
		height: 3.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tip_top .inputs_filter input{
		height: 3rem;
		border-radius: 30px;
		font-size: 1.6rem;
	}
	.tip_top .inputs_filter div{
		font-size: 1.8rem;
	}
	.resource-list-search .select-content .select-field-title{
		font-size: 1.6rem;
		padding-bottom: 1rem;
		width: auto;
	}
	.resource-list-search .select-content .select-field-list li{
		font-size: 1.5rem;
		padding: 0.5rem 0.8rem;
	}
	.allLists ul{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.checks_wraps .policyItem[data-v-0ddc6828]:nth-child(5n){
		margin: 0;
	}
	.checks_wraps .policyItem{
		width: 48%;
		height: auto;
	}
	.checks_wraps .policyItem .listBox_card_logo{
		width: 100%;
		height: 23rem;
	}
	.checks_wraps .policyItem .listBox_card_p1{
		font-size: 1.7rem;
		height: 3rem;
		line-height: 3rem;
	}
	.checks_wraps .policyItem .listBox_card_p3{
		line-height: 4rem;
	}
	.checks_wraps .policyItem .listBox_card_p3 .listBox_card_price{
		font-size: 1.6rem !important;
	}
	.checks_wraps .policyItem .listBox_card_p3 span{
		font-size: 1.6rem !important;
	}
	.checks_wraps .policyItem .kefu{
		display: none;
	}
}
</style>
